import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Box, Button, Text, Link, Flex, SimpleGrid, Spacer } from '@chakra-ui/react'

const IntroMediaBlock = ({ image, mediaName, articleTitle, link }) => {
  return (
    <Box
      bg='gray.50'
      borderRadius='10px'
      px={[2, 2, 3, 5]}
      pt={[3, 3, 3, 5]} pb='0'>
      <Flex justify='space-around' h='170px' overflow='hidden'>
        <Box w='100px' basis='0' mx='2'>
          <Link href={link} target='_blank'>
            <GatsbyImage image={image}
            imgStyle={{border:'1px solid #d9d9d9'}} />
          </Link>
        </Box>
        <Box w='250px' mx='2'>
          <Box align='center' textAlign='left'>
            <Text fontSize='md' as='b'>{mediaName}</Text>
            <Text fontSize='sm' mt='3'>{articleTitle}</Text>
          </Box>
          <Link href={link} target='_blank'
            _hover={{
              textDecoration: 'none',
            }}
          >
            <Button colorScheme='gray' size='md' my='3'>記事を見る</Button>
          </Link>
          <Spacer h='5' />
        </Box>
      </Flex>
    </Box>
  )
}

const IntroMediaGrid = () => {
  const data = useStaticQuery(graphql`
    query {
      dataYaml {
        media {
          mediaName
          articleTitle
          link
          image {
            childImageSharp {
              gatsbyImageData(
                height: 200,
                outputPixelDensities: 2,
                formats: WEBP,
              )
            }
          }
        }
      }
    }
  `)
  
  return (
    <SimpleGrid
      columns={[1, 1, 2, 2]}
      spacingX='20px' spacingY='20px'
      mx={['auto', 'auto', '-20', '-40']}
    >
      {data.dataYaml.media.map((media) => {
        return (
          <IntroMediaBlock
            image={getImage(media.image)}
            mediaName={media.mediaName}
            articleTitle={media.articleTitle}
            link={media.link}
          />
        )
      })}
    </SimpleGrid>
  )
}

export default IntroMediaGrid