import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Box, Button, Text, Link, SimpleGrid } from '@chakra-ui/react'

const IntroBookBlock = ({ image, title, meta, link }) => {
  return (
    <Box
      bg='gray.50'
      borderRadius='10px'
      px={[1, 2, 2, 2]}
      pt={[0, 0, 0, 5]}
      pb={[7, 7, 7, 5]}>
      <Box p={[5, 5, 5, 15]}>
        <Link href={link} target='_blank'>
          <GatsbyImage
            image={getImage(image)}
            imgStyle={{border:'1px solid #d9d9d9'}}
          />
        </Link>
      </Box>
      <Text as='b'>{title}</Text>
      <Text fontSize='sm'>{meta}</Text>
      <Link href={link} target='_blank'
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Button colorScheme='facebook' size='md' mt='5'>詳細を見る</Button>
      </Link>
    </Box>
  )
}

const IntroBookGrid = () => {
  const data = useStaticQuery(graphql`
    query {
      dataYaml {
        book {
          title
          meta
          link
          image {
            childImageSharp {
              gatsbyImageData(height: 150, outputPixelDensities: 2, formats: WEBP)
            }
          }
        }
      }
    }
  `)
  
  return (
    <SimpleGrid
      columns={[1, 2, 2, 2]}
      spacingX='20px' spacingY='20px'
      mx={['auto', 'auto', 'auto', '-40']}
    >
      {data.dataYaml.book.map((book) => {
        return (
          <IntroBookBlock
            image={getImage(book.image)}
            title={book.title}
            meta={book.meta}
            link={book.link}
          />
        )
      })}
    </SimpleGrid>
  )
}

export default IntroBookGrid