import React from "react"
import { Box, Container, SimpleGrid, Text, Image, Divider, AspectRatio, Link, Button } from '@chakra-ui/react'
import { BiGridAlt } from "react-icons/bi"
import Layout from '../components/layout'
import IntroPaper from '../components/intro_paper'
import IntroSectionLayout from "../components/intro_section_layout"
import IntroBookGrid from "../components/intro_book"
import IntroMediaGrid from "../components/intro_media"
import Emphasis from "../components/emphasis"
import ImageIntroExampleKokkai1 from "../images/signs/kokkaigijidomae-20090213-DSC03295-resize-w2000.jpg"
import ImageIntroExampleKokkai2 from "../images/signs/kokkaigijidomae-20190406-DSC02834-resize-w2000.jpg"
import ImageIntroExampleSugamo1 from "../images/signs/sugamo-20130414-DSC01057-resize-w2000.jpg"
import ImageIntroExampleSugamo2 from "../images/signs/sugamo-20190525-DSC03963-resize-w2000.jpg"


const IndexPage = () => {
  return (
    <Layout showCredit={true}>
      <Box
        backgroundImage='/bg-metro-1.webp'
        backgroundSize='cover'
        backgroundPosition='center'
        borderLeftWidth={[ 'none', 'none', 'none', '20px']}
        borderRightWidth={[ 'none', 'none', 'none', '20px']}
        borderColor='#aa8'
        px='0' py='50' mx='-4'
      >
        <Container align='center'>
          <IntroPaper mb='8'>
            <Text as='b'>野良サイン</Text>とは、<br />
            大雑把に言えば<br />
            駅などでみられる<br />
            手作りの案内表示のことです。
          </IntroPaper>

          <IntroPaper mb='1'>
            駅にはさまざまなサイン<Text as='small'>（＝案内表示）</Text>があります。<br />
            「ここは西出口」<br />
            「ここは2番線」<br />
            「銀座線はこちら」……<br />
          </IntroPaper>
          <IntroPaper mb='8'>
            駅の<Emphasis>オフィシャルな案内表示</Emphasis>は、鉄道会社ごとに定められた「サインシステム」に従ってデザインが決められています。
          </IntroPaper>

          <IntroPaper mb='1'>
            しかし公式の案内表示だけでは伝えきれないこともあり、それが貼り紙などの手作りのサインとして現れたりします。
          </IntroPaper>
          <IntroPaper>
            そうした、サインシステムから<Emphasis>逸脱</Emphasis>してしまっている案内表示を「<Text as='b'>野良サイン</Text>」と呼び、撮り集めています。
          </IntroPaper>

        </Container>
      </Box>

      <Divider pt='150px' />

      <IntroSectionLayout
        title='例えば'
      >
        <Box mt='80px' mb='30px' fontSize='xl'>
          <Text as='i'>
            国会議事堂前駅の難関分岐点
          </Text>
        </Box>
        <SimpleGrid
          columns={[1, 1, 1, 2]}
          spacingX='20px' spacingY='20px'
          mx={['auto', 'auto', 'auto', '-200px']}
        >
          <Box mb='3'>
            <AspectRatio ratio={3/2}>
              <Image
                src={ImageIntroExampleKokkai1}
                alt='国会議事堂前駅（2009年）'
                borderRadius='2xl'
              />
            </AspectRatio>
            <Text fontSize='sm' mt='2'>2009年</Text>
          </Box>
          <Box mb='3'>
            <AspectRatio ratio={3/2}>
              <Image
                src={ImageIntroExampleKokkai2}
                alt='国会議事堂前駅（2019年）'
                borderRadius='2xl'
              />
            </AspectRatio>
            <Text fontSize='sm' mt='2'>2019年</Text>
          </Box>
        </SimpleGrid>
        <Box align='left' mt='7' fontSize={['md','md','md','lg']} lineHeight='2'>
          <Text mb='3'>
            千代田線を降り、丸ノ内線に乗り換えようとすると途中で現れる「丸ノ内線はこちら」といった趣旨の案内の群れ。
            なぜ同じ内容が3つも並んでいるのでしょうか。
          </Text>
          <Text mb='3'>
            この場所で丸ノ内線への誘導が執拗なのには理由がある。
            丸ノ内線のホームに行くことができるのは<Emphasis>柱より手前の階段</Emphasis>だけで、<Emphasis>奥の階段</Emphasis>は出口にしか通じていない。
            地下駅の複雑なつくりと見通しの悪さが要因となって、野良サインが出現しやすいスポットになってしまっている。
          </Text>
        </Box>

        <Box mt='80px' mb='30px' fontSize='xl'>
          <Text as='i'>
            巣鴨駅の「この先入口」
          </Text>
        </Box>
        <SimpleGrid
          columns={[1, 1, 1, 2]}
          spacingX='20px' spacingY='20px'
          mx={['auto', 'auto', 'auto', '-200px']}
        >
          <Box mb='3'>
            <AspectRatio ratio={3/2}>
              <Image
                src={ImageIntroExampleSugamo1}
                alt='巣鴨駅（2013年）'
                borderRadius='2xl'
              />
            </AspectRatio>
            <Text fontSize='sm' mt='2'>2013年</Text>
          </Box>
          <Box mb='3'>
            <AspectRatio ratio={3/2}>
              <Image
                src={ImageIntroExampleSugamo2}
                alt='巣鴨駅（2019年）'
                borderRadius='2xl'
              />
            </AspectRatio>
            <Text fontSize='sm' mt='2'>2019年</Text>
          </Box>
        </SimpleGrid>
        <Box align='left' mt='7' fontSize={['md','md','md','lg']} lineHeight='2'>
          <Text mb='3'>
            オフィシャルのサインよりも大きな貼り紙。
            「都営三田線→」と同じ意味ではあるのだが、それでも「この先 入口」と書いて伝える必要があるのだろうか。
          </Text>
          <Text mb='3'>
            この写真のつきあたりを左に行くとすぐ改札口があるのだが、そこは<Emphasis>出口専用改札</Emphasis>になっていて入場はできない。
            <Emphasis>入口専用</Emphasis>の改札は、このサインたちが言っているとおり右側にあるのだが、恐らく間違える人が多いのだろう。
          </Text>
        </Box>

        <Box mt='150px'>
          <Text mb='20px'>この他にもたくさんの事例を紹介しています</Text>
          <Link href='/archive/'
            _hover={{
              textDecoration: 'none',
            }}
          >
            <Button
              leftIcon={<BiGridAlt />} px='40px' py='25px'
              colorScheme='yellow' size='xl' variant='solid' fontWeight='normal' borderRadius='none'
            >
              アーカイブ室を見る
            </Button>
          </Link>
        </Box>
      </IntroSectionLayout>

      <Divider pt='200px' />

      <IntroSectionLayout
        title='野良サインの本'
        subTitle='野良サインに関する小冊子を発行しています'
      >
        <IntroBookGrid />
      </IntroSectionLayout>

      <Divider pt='200px' />

      <IntroSectionLayout
        title='メディアでの紹介'
        subTitle='Webメディア・新聞・ラジオなどで取り上げられました'
      >
        <IntroMediaGrid />
      </IntroSectionLayout>

    </Layout>
  )
}

export default IndexPage