import * as React from 'react'
import { Box, Text, AspectRatio } from '@chakra-ui/react'

const IntroPaper = ({ mb, children }) => {
  return (
    <AspectRatio 
      width={['250px', '400px', '500px']}
      ratio='1.4142'
      mb={mb}
    >
      <Box boxShadow='base' bg='#fffffa'>
        <Text
          fontSize={['14px', '22px', '26px']}
          px={[5, 7, 9]}
          py={[2, 3, 4]}
          fontFamily='serif'
          lineHeight='1.9em'
          align='left'
        >
          {children}
        </Text>
      </Box>
    </AspectRatio>
  )
}

export default IntroPaper