import * as React from 'react'
import { Container, Box, Heading, Text } from '@chakra-ui/react'

const IntroSectionLayout = ({ title, subTitle, children }) => {

  return (
    <Box pt='50px'>
      <Container align='center' mb='10'>
        <Heading fontSize="3xl">{title}</Heading>
        <Text mt='6'>
          {subTitle}
        </Text>  
      </Container>
      <Container align='center'>
        {children}
      </Container>
    </Box>
  )
}

export default IntroSectionLayout